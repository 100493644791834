import BoatersListIcon from 'common/assets/svg/boaterslist-icon.svg'
import CanoeIcon from 'common/assets/svg/canoe-icon.svg'
import FishingIcon from 'common/assets/svg/fishing-icon.svg'
import GuideIcon from 'common/assets/svg/guide-icon.svg'
import HatIcon from 'common/assets/svg/hat-icon.svg'
import PaddlingIcon from 'common/assets/svg/paddling-icon.svg'
import ShipIcon from 'common/assets/svg/ship-icon.svg'
import ToolsIcon from 'common/assets/svg/tools-icon.svg'
import SpeedBoatIcon from 'common/assets/svg/speedboat-icon.svg'
import BoardingIconBlue from 'common/assets/svg/boarding-icon-blue.svg'
import BoardingIcon from 'common/assets/svg/boarding-icon.svg'

/*
DO NOT DELETE THE FOLLOWING COMMENTS/SCHEMA
These are helpful descriptors/correlations for cat #'s and the thier corresponding icons

ShipIcon = category 1 - Marinas
PaddlingIcon = category 2 - Rentals - actually looks like guy in canoe
ToolsIcon = category 3 - Marine Services
CanoeIcon = category 4 - Haul Out Services - actually looks like a 'standup paddle' Icon
FishingIcon = category 5 - Fishing guides and charters
HatIcon = category 6 - Crew For Hire ? this is confusing on the category page - actually looks like guy holding flag
GuideIcon = category 11 - Sales - actually looks like a Captains Hat
BoatersListIcon = category 27 for now as a catch all - Water Sports
BoardingIcon = category 9 - Board Sports - this is for all boarding related sports: surfing, kite-board, paddle-board, etc...
*/

type Category = {
  label: string
  fullLabel: string
  categoryId: string
  icon: React.ReactNode
}

const categoryObj = {
  1: <ShipIcon />,
  2: <PaddlingIcon />,
  3: <ToolsIcon />,
  4: <CanoeIcon />,
  5: <FishingIcon />,
  6: <HatIcon />,
  9: <BoardingIconBlue />,
  11: <GuideIcon />,
  27: <BoatersListIcon />,
}
export const categories: Category[] = [
  {
    label: 'View All',
    fullLabel: 'All Categories',
    categoryId: 's-0',
    icon: (
      <BoatersListIcon data-testid="boaterslistIcon" width={50} height={50} />
    ),
  },
  {
    label: 'Services',
    fullLabel: 'Marine Services',
    categoryId: 's-3',
    icon: <ToolsIcon data-testid="servicesIcon" width={50} height={50} />,
  },
  {
    label: 'Guides',
    fullLabel: 'Fishing Guides and Fishing Charters',
    categoryId: 's-5',
    icon: <FishingIcon data-testid="guidesIcon" width={50} height={50} />,
  },
  {
    label: 'Rentals',
    fullLabel: 'Rentals',
    categoryId: 's-2',
    icon: <PaddlingIcon data-testid="rentalsIcon" width={50} height={50} />,
  },
  {
    label: 'Transport',
    fullLabel: 'Transportation',
    categoryId: 's-8',
    icon: <SpeedBoatIcon data-testid="speedBoatIcon" width={50} height={50} />,
  },
  {
    label: 'Marinas',
    fullLabel: 'Marinas',
    categoryId: 's-1',
    icon: <ShipIcon data-testid="marinasIcon" width={50} height={50} />,
  },
  {
    label: 'Crew ',
    fullLabel: 'Charter Vessel and/or Captain/Crew',
    categoryId: 's-6',
    icon: <HatIcon data-testid="crewIcon" width={50} height={50} />,
  },
  {
    label: 'Sales',
    fullLabel: 'Marine Sales',
    categoryId: 's-11',
    icon: <GuideIcon data-testid="salesIcon" width={50} height={50} />,
  },
  {
    label: 'Haul Out',
    fullLabel: 'Haul Out Yard Services',
    categoryId: 's-4',
    icon: <CanoeIcon data-testid="haulIcon" width={50} height={50} />,
  },
  {
    label: 'Board Sports',
    fullLabel: 'Board sports',
    categoryId: 's-12',
    icon: <BoardingIcon width={50} height={50} />,
  },
]

export default categoryObj
